.container {
	h1, h2, h3 {
		text-transform: uppercase;
		@media (max-width: 425px) {
			text-align: center;
		}
	}
	h1 {
		font: clamp(14px, 5.3vw, 60px)/1.38 'Tactic Sans Extended', serif;
		margin: clamp(30px, 5.3vw, 60px) 0;
		text-align: center;
	}
	h2 {
		font: clamp(20px, 4.5vw, 50px) 'Gilroy-Black', sans-serif;
		margin: clamp(30px, 6.31vw, 70px) 0;
		&.centered {
			text-align: center;
		}
	}
	h3 {
		font: clamp(16px, 2.16vw, 24px) 'Gilroy-Bold', sans-serif;
		padding-bottom: 15px;
		border-bottom: 1px solid white;
	}
	>p {
		font: clamp(12px, 1.98vw, 22px) 'Gilroy-SemiBold', sans-serif;
		margin: 30px 0;
	}
	>img {
		width: 100%;
	}
	>button {
		width: 80%;
		padding: clamp(15px, 2.25vw, 25px) 0;
		margin: clamp(40px, 9.01vw, 100px) auto;
		border-radius: clamp(5px, 1.17vw, 13px);
		font-size: clamp(12px, 2.7vw, 30px);
	}
	.photos {
		display: flex;
		flex-direction: column;
		gap: clamp(30px, 6.31vw, 70px);
		margin: clamp(40px, 8.11vw, 90px) 0;
	}
}