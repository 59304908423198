.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.content {
    background-color: black;
    border-radius: 10px;
    border: 1px solid white;
    width: 800px;
    position: relative;
    padding: 40px clamp(40px, 9.75vw, 75px);
    margin: 10px;
}
.close {
    --size: 33px;
    width: var(--size);
    height: var(--size);
    object-fit: scale-down;
    position: absolute;
    top: var(--size);
    right: var(--size);
    cursor: pointer;
}
.container h1 {
    font: 40px 'Gilroy-Bold';
    margin-bottom: 30px;
}
.container form > div {
    margin: 20px 0;
}
.container form > div.errors {
    margin: 5px 0;
}
.errorMessage {
    color: #f00;
    font: 15px 'Gilroy-Regular';
    margin-top: 5px;
}
.container input, .container textarea {
    width: 100%;
    font: 18px "Gilroy-Medium";
    background-color: transparent;
    border: 1px solid white;
    padding: 15px;
    border-radius: 10px;
    box-sizing: border-box;
    
    box-shadow: inset 1000px 1000px 0 1000px black;
}
.container input:-webkit-autofill,
.container textarea:-webkit-autofill{
    -webkit-text-fill-color: white !important;
}
.container button{
    width: 100%;
    outline: unset;
    cursor: default;
    border: none;
    background: linear-gradient(to right, #c3c3c3, #4c4c4c);
    background-size: 111% 60%;
    font: 18px "Gilroy-Bold";
    padding: clamp(7px, 2vw, 15px) clamp(10px, 3.75vw, 43px);
    border-radius: 10px;
}
.container button.active:hover, .container button.active:focus{
    background-position: -1000% 0;
}
.container button.active {
    background: linear-gradient(to right, #5CC3FD, #364CA6);
    transition: background-position 1s ease;
    background-size: 111% 60%;
    cursor: pointer;
}
.container textarea {
    height: 180px;
    resize: none;
}
.ps {
    color: #B6B6B6;
}
.ps > h4 {
    font: 15px 'Gilroy-Regular';
}
.ps > p,
.fileInputWrapper > p{
    font: 10px 'Gilroy-Regular';
    margin: 10px auto;
}
.fileInputWrapper {
    position: relative;
    overflow: hidden;
}
.fileInput {
    width: 100%;
    height: 0;
    opacity: 0;
    position: absolute;
    z-index: -1;
}
.fileInputLabel {
    display: flex;
    align-items: center;
    font: clamp(10px, 1.75vw, 20px) 'Gilroy-Regular';
    border-radius: 10px;
    cursor: pointer;
    color: white;
}
.fileInputLabel > img {
    width: clamp(26px, 2.8vw, 32px);
    object-fit: scale-down;
    margin-right: clamp(10px, 1.75vw, 20px);
}
.selectedFiles {
    margin: 10px auto;
    font: 10px 'Gilroy-Regular';
    overflow-y: scroll;
    scrollbar-width: thin;
    max-height: 45px;
}
.selectedFiles > div {
    margin: 3px;
    color: #cccccc;
}
.twoColumns {
    display: flex;
    justify-content: space-between;
    gap: 20px 40px;
}
.twoColumns > * {
    width: 50% !important;
}
.confidential {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.confidential > p {
    font: clamp(8px, 1.1vw, 13px)/1.38 "Gilroy-Medium";
}
.confidential span {
    text-decoration: underline;
    cursor: pointer;
}
.fancyCheckbox {
    --size: 34px;
    width: var(--size);
    height: var(--size);
    margin-left: 10px;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
}
.fancyCheckbox > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.fancyCheckbox > div {
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, #5CC3FD, #364CA6);
    border-radius: 3px;
    position: absolute;
}
.fancyCheckbox > div > div {
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}
.fancyCheckbox img {
    width: 75%;
    height: 75%;
    object-fit: scale-down;
    display: none;
}
.fancyCheckbox > input:checked + div > div > img {
    display: block;
}
@media (max-width: 768px) {
    .container input::placeholder,
    .container textarea::placeholder,
    .container {
        text-align: center;
    }
    .content {
        width: 300px;
        padding: 15px 50px;
    }
    .container h1 {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .container textarea {
        height: 60px;
    }
    .container form > div {
        margin: 5px 0;
    }
    .container input,
    .container textarea {
        padding: 10px;
    }
    .container input,
    .container textarea,
    .container button,
    .ps > h4{
        font-size: 10px;
    }
    .ps > p,
    .fileInputWrapper > p {
        font-size: 8px;
        margin: 3px auto;
    }
    .fileInputWrapper > div {
        flex-direction: column;
    }
    .twoColumns {
        flex-direction: column;
        gap: 5px;
    }
    .twoColumns > * {
        width: 100% !important;
    }
    .close {
        --size: 14px;
    }
    .fancyCheckbox {
        --size: 20px;
    }
    .fileInputLabel {
        justify-content: center;
    }
    .selectedFiles {
        margin: 3px auto;
        font-size: 8px;
    }
    .errorMessage {
        font-size: 7px !important;
    }
}
@media (max-height: 768px) {
    .content {
        padding-top: 20px;
        padding-bottom: 0;
    }
    .container h1 {
        margin-bottom: 15px;
    }
    .fileInputWrapper > p {
        margin: 3px 0;
    }
    .errorMessage {
        font-size: 10px;
    }
    .container textarea {
        height: 105px;
    }
}

