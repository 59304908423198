.container h3 {
	font: clamp(12px, 3.5vw, 40px) 'Gilroy-SemiBold';
	width: 80%;
}
.pictureBtn {
	display: flex;
	justify-content: space-between;
	margin: clamp(50px, 8.75vw, 100px) 0 0 clamp(25px, 4.4vw, 50px);
}
.picture {
	position: relative;
	display: inline-block;
	width: 45%;
	margin-bottom: 30px;
}
.picture > div {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgb(92, 195, 253);
	z-index: -1;
	filter: blur(clamp(75px, 13.15vw, 150px));
	width: 100%;
}
.picture > img {
	position: relative;
	z-index: 1;
	width: 100%;
	height: auto;
}
.viewCase {
	margin-top: auto;
	display: flex;
	flex-wrap: nowrap;
}
.container button {
	font-size: clamp(12px, 2.2vw, 25px);
	border-radius: 10px;
}
.text {
	padding: clamp(15px, 2.2vw, 25px) clamp(70px, 11vw, 125px);
}
.arrow {
	display: inline-block;
	height: auto;
	padding: clamp(15px, 2vw, 23px);
	margin: auto 0 auto 30px;
}
.container button:hover,
.container button:focus {
	background-position: -1005% 0;
}
@media (max-width: 1140px) {
	.pictureBtn {
		flex-direction: column;
	}
}
@media (max-width: 768px) {
	.arrow {
		display: none;
	}
}
@media (max-width: 425px) {
	.container h3 {
		text-align: center;
		margin: 0 auto;
	}
	.pictureBtn {
		align-items: center;
		margin: 50px auto;
	}
}