.container {
	margin-top: clamp(50px, 8.75vw, 100px);
	font-family: Gilroy-Medium;
}
.container > h3 {
	font: clamp(16px, 4.4vw, 50px) 'Gilroy-Black';
	text-transform: uppercase;
}
.prosConsTitle {
	text-align: center;
	margin: clamp(50px, 8.75vw, 100px) 0 clamp(20px, 7.9vw, 90px);
}
.container > h4 {
	font-size: clamp(12px, 2.6vw, 30px);
	margin: clamp(20px, 3.5vw, 40px) 0 clamp(20px, 2.6vw, 30px);
}
.about {
	display: flex;
	justify-content: space-between;
	margin: clamp(30px, 7vw, 80px) 0;
}
.about > img {
	width: 9.5%;
}
.about > p {
	font: clamp(10px, 2.6vw, 30px) 'Gilroy-Medium';
	width: 85%;
}
.container > table {
	width: 100%;
	border-collapse: collapse;
}
.container th {
	font: clamp(10px, 2.2vw, 25px) 'Gilroy-SemiBold';
	background: linear-gradient(to right, #5CC3FD, #364CA6);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.container tbody > tr > td:first-child {
	font: clamp(12px, 3.5vw, 40px) 'Gilroy-Bold';
}
.container td,
.container th{
	border-bottom: 1px solid #9696967F;
}
.container th {
	padding: 10px clamp(5px, 1.75vw, 20px);
	text-align: left;
}
.container td {
	padding: clamp(10px, 3.5vw, 40px) clamp(5px, 1.75vw, 20px);
	vertical-align: top;
}
.container td > p {
	font-size: clamp(10px, 1.4vw, 16px);
}
.container td > img {
	height: clamp(25px, 4.4vw, 50px);
	margin-bottom: 15px;
}
@media (max-width: 425px) {
	.container > h3,
	.container > h4 {
		text-align: center;
	}
}
