/* Используется в компонентах WhenNeed, Principles и Awards*/
.container section {
    display: flex;
    padding-bottom: clamp(20px, 4.4vw, 50px);
    border-bottom: 1px solid #B6B6B6;
    margin-bottom: 80px;
}
.container h3 {
    font: clamp(14px, 3.5vw, 40px) 'Gilroy-Bold';
    margin-bottom: clamp(20px, 2.6vw, 30px);
}
.container p {
    font: clamp(10px, 2.2vw, 25px) 'Gilroy-Regular';
}
.checkmark {
    position: relative;
    display: inline-block;
    height: auto;
    margin-bottom: auto;
}
.checkmark > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(92, 195, 253, 0.87);
    z-index: -1;
    filter: blur(clamp(30px, 3vw, 50px));
    border-radius: 50%;
    width: 100%;
}
.checkmark > img {
    width: clamp(40px, 8.8vw, 100px);
    height: clamp(40px, 8.8vw, 100px);
    object-fit: scale-down;
}
.textContainer {
    margin-left: 50px;
}
@media (max-width: 425px) {
    .container section {
        flex-direction: column;
    }
    .checkmark {
        display: flex;
        justify-content: center;
    }
    .checkmark > div {
        width: 50%;
        left: 50%;
        transform: translateX(-50%);
        filter: blur(50px);
    }
    .textContainer {
        margin-left: 0;
        margin-top: 50px;
        text-align: center;
    }
}
