.container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Добавляет расстояние между фотографиями */
}
.container img {
    object-fit: cover;
    border-radius: 8px; /* К примеру, можно сделать углы скругленными */
    flex: 1 400px;
    width: 100%;
}