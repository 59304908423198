.container {
    display: flex;
    justify-content: space-between;
}
.container > div {
    text-align: center;
    flex-grow: 1;
    border-right: 1px solid #969696;
    border-collapse: collapse;
}
.container > div:last-child {
    border-right: none;
}
.container h3 {
    font: clamp(50px, 8vw, 90px) 'Gilroy-Black';
    background: linear-gradient(90deg, #5CC3FD 33%, #4A5EBC 66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.container p {
    font: clamp(10px, 1.75vw, 20px) 'Gilroy-Regular';
    width: 60%;
    margin: 0 auto;
}
@media (max-width: 425px) {
    .container {
        flex-direction: column;
        align-items: center;
    }
    .container > div {
        width: 45%;
        border-bottom: 1px solid #969696;
        border-right: none;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
}
