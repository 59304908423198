.container h3 {
	font: clamp(16px, 4.4vw, 50px) 'Gilroy-Black';
	margin: clamp(40px, 8.8vw, 100px) 0 clamp(30px, 3.5vw, 40px);
	text-align: center;
	text-transform: uppercase;
}
.row {
	display: flex;
	justify-content: space-between;
	padding-bottom: clamp(5px, 3.5vw, 40px);
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	margin-bottom: clamp(20px, 5.3vw, 60px);
}
.row > div {
	font: clamp(12px, 3vw, 35px) 'Gilroy-SemiBold';
	margin: auto 0;
}
.row > img {
	width: clamp(25px, 6.6vw,75px);
}