.container {
	/*	base 250px*/
	display: flex;
	flex-wrap: wrap;
	gap: clamp(0px, 4vw, 50px);
}
.container > div {
	flex: 1 250px;
	padding: clamp(15px, 1.75vw, 20px);
	border-radius: 10px;
}
.num {
	font: clamp(30px, 3.5vw, 40px) 'Gilroy-Bold';
	background: linear-gradient(90deg, #5CC3FD 33%, #4A5EBC 66%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.name {
	font: 15px 'Gilroy-Medium';
	margin: clamp(15px, 2.6vw, 30px) 0 clamp(7px, 1.3vw, 15px);
}

@media (max-width: 425px) {
	.num, .name {
		text-align: center;
	}
}