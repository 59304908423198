.container {
    margin: 150px auto;
}
.itemsWrap {
    display: flex;
    overflow: hidden;
    gap: clamp(10px, 4.4vw, 50px);
}
.items {
    flex-shrink: 0;
    display: flex;
    gap: 50px;
    justify-content: space-around;
    animation: scroll 20s linear infinite;
}
@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - clamp(10px, 4.4vw, 50px)));
    }
}
.container img {
    object-fit: scale-down;
}
@media (max-width: 425px) {
    .container {
        margin: 70px auto;
    }
}