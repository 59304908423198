.container > h3 {
    font: clamp(16px, 3.5vw, 40px) 'Gilroy-SemiBold';
    padding-bottom: 32px;
    border-bottom: 2px solid white;
    margin-bottom: 40px;
}
.container > h3 > b {
    color: #5CC3FD;
}
.text {
    font: clamp(16px, 3vw, 32px) 'Gilroy-SemiBold';
    hyphens: manual;
    width: 70%;
}
.text > b {
    color: #5CC3FD;
}
.container form {
    display: block;
    margin: 60px auto 85px;
}
.container form > div {
    margin: clamp(20px, 2.7vw, 30px) 0;
}
.container input, .container textarea {
    outline: unset;
    box-sizing: border-box;
    width: 100%;
    background-color: transparent;
    padding: clamp(10px, 2vw, 25px) 15px;
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: clamp(3px, 18%, 10px);
    font: clamp(8px, 1.6vw, 18px) 'Gilroy-Medium';
    color: white;
    box-shadow: inset 1000px 1000px 0 1000px black;
}
.container input:-webkit-autofill,
.container textarea:-webkit-autofill{
    -webkit-text-fill-color: white !important;
}
.message {
    height: 150px;
    resize: none;
}
.errorMessage {
    color: #d20303;
    font: clamp(7px, 1.3vw, 15px) 'Gilroy-Regular';
    margin-top: 5px;
}
.ps > h4 {
    font: clamp(11px, 1.5vw, 17px) 'Gilroy-Regular';
    color: #B6B6B6;
}
.ps > p, .fileInputWrapper > p {
    font: clamp(9px, 1.15vw, 13px) 'Gilroy-Regular';
    color: #B6B6B6;
    margin: 10px 0;
}
.selectedFiles {
    margin: 10px;
    font: 10px 'Gilroy-Regular';
    overflow-y: scroll;
    scrollbar-width: thin;
    max-height: 45px;
}
.selectedFiles > div {
    margin: 3px;
    color: #cccccc;
}
.fileInputWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
}
.fileInput {
    width: 100%;
    height: 0;
    opacity: 0;
    position: absolute;
    z-index: -1;
}
.fileInputLabel {
    display: flex;
    align-items: center;
    font: clamp(11px, 2.25vw, 25px) 'Gilroy-Regular';
    border-radius: 10px;
    cursor: pointer;
    color: white;
}
.fileInputLabel > img {
    width: clamp(26px, 5vw, 57px);
    object-fit: scale-down;
    margin-right: clamp(5px, 3vw, 35px);
}
.fileInputLabel::before {
    font-size: clamp(11px, 2.25vw, 25px);
}

.fileName {
    font-size: clamp(10px, 2vw, 20px);
    color: #969696;
    margin-top: 5px;
}

.container button{
    padding: clamp(8px, 2vw, 25px) 15px;
    background: linear-gradient(to right, #c3c3c3, #4c4c4c);
    border: none;
    border-radius: 10px;
    font-family: Gilroy-SemiBold, sans-serif;
    font-size: clamp(10px, 2vw, 25px);
    width: 100%;
}
.container button > p{
    color: black;
    font-family: 'Gilroy-SemiBold';
    font-weight: 900;
}
.container button > img {
    object-fit: scale-down;
    width: clamp(5px, 3vw, 30px);
}
.personalData{
    font: clamp(7px, 1.75vw, 20px) 'Gilroy-Regular';
    margin-top: 6px;
}
.personalData span {
    text-decoration: underline;
    cursor: pointer;
}
.connection {
    display: flex;
    justify-content: space-between;
}
.tg{
    display: flex;
    justify-content: space-between;
    font: clamp(10px, 2vw, 24px) 'Gilroy-SemiBold';
    border: 2px solid white;
    padding: clamp(10px, 2.5vw, 30px) clamp(5px, 1vw, 15px);
    border-radius: 10px;
    cursor: pointer;
}
.tg > span {
    margin-right: clamp(5px, 1vw, 15px);
    text-wrap: nowrap;
}
.tg b {
    color: #5CC3FD;
}
.tg > img {
    width: clamp(18px, 4vw, 44px);
    object-fit: scale-down;
}
.connectionPhone{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.phone{
    font-size: clamp(12px, 2.5vw, 30px);
    font-family: 'Gilroy-SemiBold';
    text-align: center;
}
.callback{
    font-size: clamp(9px, 2vw, 24px);
    font-family: 'Gilroy-Regular';
    margin-top: 5px;
    border-bottom: 2px dashed #5CC3FD;
}
.callback b {
    color: #5CC3FD;
}
.twoColumns {
    display: flex;
    justify-content: space-between;
    gap: 20px clamp(20px, 3.6vw, 40px);
}
.twoColumns > * {
    width: 50% !important;
}
.twoColumns.uneven > *:first-child {
    width: 35% !important;
}
.twoColumns.uneven > *:last-child {
    width: 65% !important;
}
.confidential {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.confidential > p {
    font: clamp(8px, 1.1vw, 13px)/1.38 "Gilroy-Medium";
}
.confidential span {
    text-decoration: underline;
    cursor: pointer;
}
.fancyCheckbox {
    --size: clamp(20px, 3.06vw, 34px);
    width: var(--size);
    height: var(--size);
    margin-left: 10px;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
}
.fancyCheckbox > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.fancyCheckbox > div {
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, #5CC3FD, #364CA6);
    border-radius: 3px;
    position: absolute;
}
.fancyCheckbox > div > div {
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}
.fancyCheckbox img {
    width: 75%;
    height: 75%;
    object-fit: scale-down;
    display: none;
}
.fancyCheckbox > input:checked + div > div > img {
    display: block;
}
@media (max-width: 768px) {
    .fileInputWrapper > div {
        flex-direction: column;
    }
}
@media (max-width: 425px) {
    .container > h3, .text, .container {
        text-align: center;
    }
    .text {
        width: 90%;
        margin: 0 auto;
    }
    .container input, .container textarea {
        border: 1px solid rgba(255, 255, 255, 0.8);
        text-align: center;
        margin-bottom: 0px;
    }
    .container form {
        margin: 50px auto 30px;
    }
    .ps {
        text-align: center;
    }
    .ps > p {
        width: 85%;
        margin: 0 auto;
    }
    .fileInputLabel {
        justify-content: center;
    }
    .container button > p {
        margin: 0 auto;
    }
    .callback {
        border-bottom: 1px dashed #5CC3FD;
    }
    .tg {
        border: 1px solid white;
    }
    .container form > div {
        margin: 5px 0;
    }
    .twoColumns {
        flex-direction: column;
        gap: 5px;
    }
    .twoColumns > * {
        width: 100% !important;
    }
    .fileInputLabel {
        justify-content: center;
    }
}