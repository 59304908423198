.container {
    width: 80%;
    margin: 0 auto;
}
.header  {
    text-align: center;
}
.block {
    display: flex;
    align-items: flex-start;
}
.img {
    margin: 0;
    width: clamp(17px, 4.4vw, 50px);
    flex-shrink: 0;
}
.content {
    border: 1px solid #ffffff80;
    border-radius: 5px;
    margin: 0 0 0 25px;
    width: 100%;
}
.content > p {
    font: clamp(10px, 2.2vw, 25px)/1.38 'Gilroy-Medium';
    margin: clamp(10px, 3vw, 35px) 3.5%;
}

@media (max-width: 425px) {
    .container {
        width: 100%;
    }
}