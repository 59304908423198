.header{
    position: sticky;
    top: 0;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.58);
    z-index: 1000;
    box-sizing: border-box;
}
.dots {
    writing-mode: vertical-rl;
    text-align: center;
}
.intoHeader {
    display: flex;
    justify-content: space-between;
    max-width: 1140px;
    padding: 0 30px;
    margin: 0 auto;
}
.leftPart {
    color: white;
    display: flex;
    justify-content: space-between;
    width: 80%;
    font-size: clamp(11px, 2vw, 23px);
    position: relative;
}

.logo {
    font: clamp(30px, 3.5vw, 40px) 'Tactic Sans Extended';
    display: flex;
    cursor: pointer;
    margin-right: 15px;
}
.logo.mini {
    font-size: clamp(20px, 3.5vw, 40px);
    margin-right: clamp(5px, 1.3vw, 15px);
    align-items: center;
}
.link {
    font-family: 'Gilroy-SemiBold';
    cursor: pointer;
    margin: auto 10px;
    display: flex;
    position: relative;
    text-wrap: nowrap;
}

.openIcon {
    cursor: pointer;
    width: clamp(25px, 4.4vw, 50px);
    height: clamp(25px, 4.4vw, 50px);
    background-size: clamp(25px, 4.4vw, 50px);
    background-image: url('../../../public/Pictures/burger.svg');
    background-position: center;
    background-repeat: no-repeat;
}
.iconAndButton{
    display: flex;
    align-items: center;
}
.orderBtnMenu{
    font-size: 15px;
    padding: clamp(7px, 2vw, 15px) clamp(10px, 3.75vw, 43px);
    margin-right: clamp(10px, 5.3vw, 60px);
    border-radius: 10px;
}

.closeIcon {
    cursor: pointer;
    width: clamp(30px, 4.4vw,50px);
    height: clamp(30px, 4.4vw,50px);
    background-size: clamp(30px, 4.4vw,50px);
    background-image: url('../../../public/Pictures/burger-close.svg');
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
}

.burger > span {
    width: 45px;
    height: 2px;
    background-color: white;
    display: block;
    margin-top: 11px;
    margin-bottom: 11px;
}
/* Новый стиль для модального окна с анимацией */
.modalContainer {
    position: absolute;
    top: 115%;
    left: 0;
    background-color: #000000;
    color: white;
    padding: 10px;
    border-radius: 8px;
    z-index: 100;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px);
    transition: all .3s ease;
    box-shadow: 0 0 12px 6px rgba(40, 68, 143, 0.73);
}
.modalContainer.active {
    visibility: visible;
    opacity: 1;
    transform: translateX(0px);
}
.modalContainer > p {
    margin-bottom: 8px;
    margin-top: 5px;
    cursor: pointer;
    font: 17px 'Gilroy-Regular';
}
.openMenu {
    position: fixed;
    width: 100%;
    top: 0;
    height: 0;
    overflow: hidden;
    transition: height 0.7s ease;
    z-index: 2000;
}
.openMenu.active {
    height: 100vh;
}
.openMenu > div {
    position: relative;
    padding-top: 20px;
    width: 100%;
    height: 100%;
    background-color: #000000;
}
.openMenu > div > div {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100vh;
}
.openMenu > div > div > div {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 30px;
}
.openMenuHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: clamp(20px, 4.4vw, 50px);
    border-bottom: 1px solid white;
}
.headMenu{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
    padding-bottom: 35px;
}
.bodyMenu{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.menuLeft, .menuRight{
    width: 40%;
}
.item{
    display: flex;
    font: clamp(20px, 3.65vw, 28px) 'Gilroy-Regular';
    margin-top: 40px;
    cursor: pointer;
    text-wrap: nowrap;
}
.phoneNumber{
    background: linear-gradient(to right, #5CC3FD, #364CA6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font: 900 clamp(14px, 2.6vw, 20px) 'Gilroy-Regular';
    margin-top: 30px;
    cursor: default;
    text-wrap: nowrap;
}
.servList, .prodList{
    margin-top: 20px;
}
.servList > li, .prodList > li{
    list-style-type: none;
    font-family: 'Gilroy-Regular';
    font-size: 18px;
    margin-bottom: 10px;
    cursor: pointer;
}
.socialLinks{
    display: flex;
    gap: 30px;
    margin-top: 30px;
}
.socialLinks > img{
    cursor: pointer;
}
.noScroll {
    overflow: hidden;
}
.noScroll {
    overflow: hidden;
}
@media (max-width: 620px) {
    #services, #products {
        display: none;
    }
}
@media (max-width: 425px) {
    .bodyMenu {
        flex-direction: column;
    }
    .menuLeft, .menuRight{
        width: 100%;
    }
    .openMenu > div > div > div {
        padding: 0 10px;
    }
    .intoHeader {
        padding: 0 10px;
    }
    .item {
        margin-top: 20px;
    }
}
