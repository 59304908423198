.container{
    margin-top: 100px;
}
.container > h1 {
    font: bold clamp(23px, 7.5vw, 110px) 'Tactic Sans Extended';
    text-align: center;
    text-transform: uppercase;
}
.container > h1::first-letter {
    color: #5CC3FD;
}
.container > h2 {
    font: clamp(14px, 4vw, 70px) 'Tactic Sans Extended';
    color: white;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.container > h3 {
    font: clamp(12px, 2.5vw, 30px) 'Gilroy-SemiBold';
    margin: 80px auto 0;
    text-align: center;
    width: 60%;
}
.orderButton {
    font-size: clamp(12px, 2.5vw, 30px);
    width: 65%;
    margin: 70px auto 0;
    padding: 25px 0;
    border-radius: 10px;
}

@media (max-width: 767px) {
    .container {
        margin-top: 50px;
    }
    .container > h2, .container > h3, .orderButton  {
        margin-top: 30px;
    }
}

@media (max-width: 425px) {
    .container {
        margin-top: 0;
    }
    .orderButton {
        padding: 15px 0;
    }
    .container > h3 {
        width: 80%;
    }
}
