/* Блок с колокольчиком и текстом в рамке */
.container {
	display: flex;
	align-items: flex-start;
	margin: clamp(20px, 2.6vw, 30px);
}
.container > img {
	margin: 0;
	width: clamp(17px, 4.4vw, 50px);
	flex-shrink: 0;
}
.container > div {
	margin-left: clamp(5px, 2.2vw, 25px);
	width: 100%;
}
.container p {
	font: clamp(10px, 2.2vw, 25px)/1.38 'Gilroy-Medium';
	padding: clamp(10px, 3vw, 35px) 3.5%;
	border: 1px solid #ffffff80;
	border-radius: 5px;
	margin-bottom: clamp(15px, 3.5vw, 40px);
	white-space: pre-line;
}