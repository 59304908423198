.container > h3 {
	font: clamp(14px, 3.5vw, 40px) 'Gilroy-SemiBold';
	margin: clamp(40px, 8vw, 90px) auto;
}
.table {
	padding: clamp(20px, 4.4vw, 50px);
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 5px;
}
.table > div {
	display: flex;
	justify-content: space-between;
	font: clamp(10px, 2.2vw, 25px) Gilroy-SemiBold;
	padding-bottom: clamp(10px, 1.3vw, 15px);
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	margin-bottom: clamp(20px, 3.5vw, 40px);
}
.table > div:last-child {
	margin-bottom: 0;
}
@media (max-width: 425px) {
	.container > h3 {
		text-align: center;
	}
}