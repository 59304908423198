.arguments {
	display: flex;
	gap: clamp(0px, 4vw, 50px);
	flex-wrap: wrap;
}
.arguments > div {
	box-shadow: 0 0 40px 15px rgba(54, 76, 166, 0.4);
	font: clamp(12px, 1.6vw, 18px) 'Gilroy-Medium';
	padding: 20px;
	border-radius: 10px;
	background: radial-gradient(circle, rgba(92, 195, 253, 0.24) 15%, #000000 100%);
	display: flex;
	flex-direction: column;
	flex: 1 150px;
}
.arguments img {
	width: 50px;
}
.arguments p {
	margin: 30px auto;
}
.conclusion > h3 {
	font: clamp(16px, 4.4vw, 50px) 'Gilroy-Black';
	text-align: center;
	text-transform: uppercase;
	margin: clamp(50px, 8.8vw, 100px) 0 clamp(20px, 2.6vw, 30px);
}
@media (max-width: 425px) {
	.arguments > div {
		align-items: center;
	}
	.arguments p {
		text-align: center;
		width: 75%;
	}
}
