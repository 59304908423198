.container{
    display: flex;
    justify-content: space-between;
}
.left{
    font-family: 'Gilroy-SemiBold';
    font-weight: 900;
    width: 41%;
}
.right{
    width: 55%;
    height: clamp(176px, 28vw, 321px);
}
.container h3{
    font-size: clamp(15px, 2.8vw, 32px);
    margin-bottom: clamp(0px, 5.3vw, 60px);
}
.left > p{
    font: clamp(15px, 2.5vw, 28px) 'Gilroy-SemiBold';
    margin-top: clamp(15px, 2.6vw, 30px);
}
.left > p.clickable {
    cursor: pointer;
}
.left > p > b {
    color: #5CC3FD;
    text-wrap: balance;
}
.vkLink{
    display: inline-block;
    border-bottom: 1px solid white;
    cursor: pointer;
}
.nowrap {
    text-wrap: nowrap !important;
}

@media (max-width: 425px) {
    .container {
        flex-direction: column;
    }
    .left, .right {
        width: 100%;
    }
    .container h3, .left p {
        text-align: center;
    }
    .container h3 {
        margin-bottom: 0;
    }
    .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }
}