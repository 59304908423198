.container section {
    display: flex;
    justify-content: space-between;
    margin: clamp(15px, 4vw, 45px) auto;
    gap: 0 60px;
}
.container > section > div {
    flex-grow: 1;
    flex-basis: clamp(0px, 35%, 400px);
}
.container h3 {
    font: clamp(16px, 2.1vw, 24px) 'Gilroy-SemiBold';
    width: 60%;
    border-bottom: 1px solid white;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.container p {
    font: clamp(12px, 1.9vw, 22px)/1.5 'Gilroy-SemiBold';
    margin: 15px 0 clamp(0px, 2.6vw, 30px);
}
.container img {
    width: clamp(0px, 100%, 400px);
    object-fit: contain;
    flex-grow: 1;
    margin-bottom: 40px;
}
.left {
    flex-wrap: wrap;
}
.right {
    flex-wrap: wrap-reverse;
}
.middle {
    flex-direction: column;
}
.middle > img {
    width: 100%;
}
@media (max-width: 425px) {
    .container h3 {
        margin: 0 auto 15px;
        text-align: center;
    }
    .container p {
        text-align: center;
    }
    .container img {
        margin-bottom: 10px;
    }
}
