.container {
	.vacancies {
		display: flex;
		flex-wrap: wrap;
		gap: clamp(0px, 5vw, 55px);
		justify-content: space-between;
		margin-top: 20px;
		font-family: 'Tactic Sans Extended', serif;
		
		.vacancy {
			flex: 1 339px;
			min-width: 339px;
			border-radius: 10px;
			box-shadow: 0 0 32px 6px rgba(54, 76, 166, 0.55);
			background: radial-gradient(circle, rgba(92, 195, 253, 0.24) 2%, #000000 90%);
			transition: box-shadow 0.5s ease, transform 0.5s ease;
			text-align: center;
			&:hover, &:focus {
				box-shadow: 0 0 32px 6px rgba(92, 195, 253, 0.58);
				transform: scale(1.1);
			}
			
			.logo{
				font-size: 18px;
				text-transform: uppercase;
				margin-top: clamp(5px, 10%, 35px);
				&:first-letter {
					color: #5CC3FD;
				}
			}
			.headers {
				height: 140px;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			h3 {
				height: 54px;
				font-size: 15px;
				width: 90%;
				margin: 10px auto;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			h4 {
				height: 18px;
				font: 15px Gilroy-Bold;
				color: #5CC3FD;
				margin: 10px auto 0;
			}
			button {
				width: 85%;
				margin: clamp(5px, 12%, 40px) auto 0;
				font: 14px Gilroy-Bold;
				padding: clamp(5px, 4%, 13px) 0;
				border-radius: 5px;
			}
			.hashtag{
				font: 10px 'Tactic Sans Extended Light Italic';
				color: rgba(255, 255, 255, 0.8);
				text-align: center;
				text-transform: uppercase;
				margin: clamp(5px, 6%, 20px) auto clamp(5px, 7.5%, 25px);
			}
		}
	}
	.toggleButton {
		width: 80%;
		display: none;
		padding: 15px 0;
		margin: 70px auto;
		border-radius: 10px;
		@media (max-width: 425px) {
			display: block;
		}
	}
}