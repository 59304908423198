.container {
	margin: clamp(50px, 8.8vw, 100px) auto clamp(70px, 13.2vw, 150px);
	max-width: 740px;
}
.container > h3 {
	font: clamp(16px, 4.4vw, 50px) 'Gilroy-Bold';
	text-align: center;
	text-transform: uppercase;
	margin: clamp(50px, 8.8vw, 100px) 0 clamp(15px, 3.5vw, 40px);
}
.container > h4 {
	font: clamp(12px, 2.6vw, 30px) 'Gilroy-Medium';
	margin: clamp(15px, 3.5vw, 40px) 0 clamp(15px, 2.6vw, 30px);
	text-align: center;
}