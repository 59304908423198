.item {
    margin-bottom: clamp(20px, 6.5vw, 75px);
}
.head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
    padding-bottom: clamp(10px, 4.5vw, 50px);
}
.container h3 {
    font: clamp(16px, 3vw, 32px) 'Gilroy-SemiBold';
    color: #5CC3FD;
    height: auto;
    margin: auto 0;
    width: 80%;
}
.button {
    width: clamp(21px, 5vw, 56px);
    height: clamp(21px, 5vw, 56px);
    border: 1px solid white;
    font-size: clamp(12px, 1.75vw, 20px);
    text-align: center;
    line-height: 24px;
    border-radius: clamp(3px, 18%, 10px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container p {
    font: clamp(12px, 2vw, 26px) "Gilroy-Regular";
    color: white;
    width: 84%;
    margin-top: clamp(10px, 4.5vw, 50px);
    overflow: hidden;
    transition: max-height 0.5s ease; /* Плавная анимация высоты */
    max-height: 0; /* Скрыт по умолчанию */
    margin-bottom: 0;
}
