.container {
	display: flex;
	flex-direction: row;
	gap: 8.75%;
	font: clamp(8px, 1.4vw, 16px)/1.38 'Gilroy-Regular';
	margin: clamp(40px, 7.9vw, 90px) 0;
	div {
		flex: 1 40%;
	}
	.tags {
		display: flex;
		flex-wrap: wrap;
		gap: clamp(10px, 1.75vw, 20px);
		margin: clamp(15px, 4.4vw, 50px) 0;
		p {
			cursor: default;
			border: 1px solid white;
			border-radius: 5px;
			padding: clamp(5px, 1.1vw, 13px);
			text-align: center;
			flex: auto;
			transition: color, background-color .5s ease;
			&:hover {
				color: black;
				background-color: white;
			}
		}
	}
	div:last-child {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		align-items: flex-start;
		height: fit-content;
		@media (max-width: 425px) {
			display: none;
		}
	}
	h3 {
		font: 2.25em 'Gilroy-Bold';
		@media (max-width: 768px) {
			font-size: 1.5em;
		}
	}
	h4 {
		font-size: 1.25em;
		margin: clamp(15px, 2.6vw, 30px) 0;
	}
	img {
		width: 100%;
	}
	button {
		margin: clamp(30px, 6.15vw, 70px) 0;
		padding: clamp(5px, 1.95vw, 15px) clamp(20px, 8.75vw, 100px);
		border-radius: 7px;
		@media (max-width: 425px) {
			padding: 15px 70px;
			margin: clamp(30px, 6.15vw, 70px) auto;
		}
	}
	.mobileImg {
		display: none;
		margin: 30px 0;
		@media (max-width: 425px) {
			display: block;
		}
	}
	@media (max-width: 768px) {
		gap: 3.75%;
	}
	@media (max-width: 425px) {
		font-size: 10px;
		text-align: center;
	}
}