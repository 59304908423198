/* src/components/services/Services.css */
.container{
    margin-top: 150px;
}
.services {
    display: flex;
    flex-wrap: nowrap;
    gap: 5%;
    justify-content: space-between;
    margin-top: 20px;
}
.services > div {
    width: 30%;
    height: 30%;
    border-radius: 10px;
    box-shadow: 0 0 32px 6px rgba(54, 76, 166, 0.55);
    background: radial-gradient(circle, rgba(92, 195, 253, 0.24) 2%, #000000 90%);
    transition: box-shadow 0.5s ease, transform 0.5s ease;
}
.services > div:hover, .services > div:focus{
    box-shadow: 0 0 32px 6px rgba(92, 195, 253, 0.58);
    transform: scale(1.1);
}
.logo{
    font: 18px 'Tactic Sans Extended', serif;
    text-align: center;
    text-transform: uppercase;
    margin-top: clamp(5px, 10%, 35px);
}
.logo::first-letter {
    color: #5CC3FD;
}
.serviceName{
    font: clamp(10px, 1.5vw, 20px) 'Tactic Sans Extended', serif;
    text-align: center;
    width: 90%;
    margin: clamp(5px, 25%, 85px) auto 0;
}
.cardOrder{
    width: 85%;
    margin: clamp(5px, 12%, 40px) auto 0;
    font-size: clamp(10px, 1vw, 14px);
    padding: clamp(5px, 4%, 13px) 0;
    border-radius: 5px;
}
.hashtag{
    font: clamp(8px, 0.8vw, 12px) 'Tactic Sans Extended Light Italic';
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    text-transform: uppercase;
    margin: clamp(5px, 6%, 20px) auto clamp(5px, 7.5%, 25px);
}
@media (max-width: 425px) {
    .container {
        margin: 70px auto;
    }
    .services {
        flex-direction: column;
        gap: 40px;
    }
    .services > div {
        width: 100%;
    }
    .serviceName {
        font-size: 20px;
    }
    .cardOrder {
        font-size: 14px;
    }
    .hashtag {
        font-size: 12px;
    }
}