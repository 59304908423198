.container h1 {
    font: clamp(14px, 5.3vw, 60px)/1.38 'Tactic Sans Extended', serif;
    margin: clamp(30px, 5.3vw, 60px) 0;
    text-transform: uppercase;
}
.container h2 {
    font: bold clamp(16px, 4.4vw, 50px)/1.2 'Gilroy-Bold';
    margin: clamp(20px, 4.4vw, 50px) 0;
    text-transform: uppercase;
}
.img {
    margin: clamp(30px, 7.9vw, 90px) 0 clamp(20px, 5.3vw, 60px);
    width: 100%;
}
.container ul, .container ol {
    padding-left: clamp(20px, 3.5vw, 40px);
}
.block {
    margin-top: 150px;
}
.container section {
    font: clamp(12px, 2.6vw, 30px)/1.5 'Gilroy-Medium';
    margin: 40px 0;
}
.container section p,
.container h3,
.container ul.bold > li,
.bold > li > ul,
.bold > li > ol {
    margin: clamp(20px, 3.5vw, 40px) 0;
}
.container section p {
    font-weight: normal;
}
.container h3 {
    font: bold clamp(12px, 2.6vw, 30px)/1.5 'Gilroy-Medium';
}
.readingTime {
    width: fit-content;
    margin: clamp(20px, 3.5vw, 40px) 0;
    padding: 15px 34px;
    box-shadow: 0 0 40px 0 #364CA6;
    font: clamp(12px, 1.4vw, 16px)/1.3 'Gilroy-Regular', sans-serif;
}
.client {
    display: flex;
    justify-content: space-between;
}
.logo {
    margin: auto 0;
    width: auto;
}
.bold {
    font-weight: bold !important;
}
.bold > * > * {
    font-weight: normal;
}
.blue {
    background-image: linear-gradient(to right, #5CC3FD, #364CA6);
    color: transparent;
    -webkit-background-clip: text;
}
.dash {
    list-style-type: "—";
}
.dash > li {
    padding-left: clamp(10px, 1.75vw, 20px);
}
.disc {
    list-style-type: disc;
}
.circle {
    list-style-type: circle;
}

@media (max-width: 425px) {
    .container h1,
    .container h2 {
        text-align: center;
    }
    .client {
        flex-direction: column;
    }
    .readingTime {
        margin: clamp(20px, 3.5vw, 40px) auto;
    }
    .logo {
        width: 50%;
        align-self: center;
    }
}