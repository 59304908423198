.values{
    display: flex;
    flex-wrap: wrap;
    gap: 46px 4%;
    justify-content: center;
    margin-top: 20px;
}
.value{
    width: 339px;
    height: clamp(239px, 30vw, 339px);
    border-radius: 10px;
    box-shadow: 0px 0px 32px 6px rgba(54, 76, 166, 0.55);
    background: radial-gradient(circle, rgba(92, 195, 253, 0.24) 2%, #000000 90%);
}
.value-number{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    text-align: right;
    font-family: 'Gilroy-SemiBold';
    font-size: 20px;
    background: linear-gradient(180deg, #5CC3FD 0%, #364CA6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.value-name{
    font-family: 'Gilroy-SemiBold';
    font-size: clamp(16px, 1.75vw, 20px);
    width: 75%;
    margin-left: 25px;
    margin-top: 20px;
}
.value > span{
    display: block;
    width: 60px;
    height: 1px;
    margin-left: 25px;
    margin-top: 12px;
    background-color: #fff;
}
.value-text{
    font-family: 'Gilroy-Regular';
    font-size: clamp(14px, 1.6vw, 18px);
    width: 85%;
    margin-left: 25px;
    margin-top: 35px;
}
.value-order{
    margin-left: 25px;
    margin-top: 30px;
    width: 65%;
    font-size: 12px;
    padding: 11px 0;
    border-radius: 5px;
}
@media (max-width: 425px) {
    .value, .value-number {
        text-align: center;
    }
    .value > span {
        margin: 12px auto 0;
    }
}