.container{
    margin-top: clamp(40px, 7.7vw, 88px);
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
    flex-wrap: wrap;
    gap: 15px;
}
.column {
    flex-grow: 1;
}
.column > h2 {
    font: clamp(12px, 2.8vw, 32px) 'Gilroy-SemiBold';
    color: #5CC3FD;
    margin-bottom: clamp(10px, 2.2vw, 25px);
}
.column > p {
    font: clamp(7px, 1.75vw, 20px) 'Gilroy-Regular';
    margin-bottom: clamp(8px, 1.75vw, 20px);
}
.clickable {
    cursor: pointer;
}
.icons{
    display: flex;
    gap: clamp(20px, 2.6vw, 30px);
    justify-content: center;
    flex-grow: 1;
}
.icons > img{
    margin-bottom: auto;
    cursor: pointer;
}
.copyright{
    font: clamp(15px, 1.75vw, 20px) 'Gilroy-Regular';
    margin: 30px 0 clamp(40px, 6.1vw, 70px);
    display: flex;
}
.copyright > p:last-child {
    margin-left: 70px;
}
@media (max-width: 768px) {
    .icons > img {
        width: clamp(30px, 6.5vw, 50px);
    }
}
@media (max-width: 425px) {
    .copyright {
        text-align: center;
        flex-direction: column;
    }
    .copyright > p:last-child {
        margin-top: 20px;
        margin-left: 0;
    }
}
