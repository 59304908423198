.container {
    margin: 100px 0;
    text-align: center;
}
.container > h3 {
    font: clamp(16px, 4vw, 50px) 'Gilroy-Black';
    max-width: 700px;
    margin: 0 auto;
    text-transform: uppercase;
}
.container > p {
    font: clamp(12px, 2.6vw, 30px) 'Gilroy-SemiBold';
    margin: clamp(5px, 3.5vw, 40px) 0;
}
.container > button {
    font: clamp(12px, 2.6vw, 30px) 'Gilroy-SemiBold';
    width: 70%;
    border-radius: 10px;
    padding: clamp(12px, 2.2%, 25px);
    color: white;
    background: linear-gradient(to right, #5CC3FD, #364CA6);
    background-size: 110% 60%;
    transition: background-position 1s ease;
    border: none;
    cursor: pointer;
}
.container > button:hover, .container > button:focus {
    background-position: -1010% 0;
}