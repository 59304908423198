*{
    margin: 0;
    padding: 0;
    color: white;
}
/* scrollbars */
* {
    scrollbar-width: thin;
    scrollbar-color: gray black;
}

/* Работает в Chrome, Edge и Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: black;
}

*::-webkit-scrollbar-thumb {
    background-color: gray;
}
/* /scrollbars */
.block-title {
    font: clamp(20px, 4vw, 70px) 'Tactic Sans Extended', serif !important;
    margin: clamp(70px, 13.2vw, 150px) auto clamp(40px, 8vw, 90px) !important;
    text-transform: uppercase  !important;
}
.soft {
    font-size: 1.25em;
}
/* Синяя кнопка с анимацией сдвига направо */
.blueButton {
    display: block;
    position: relative;
    overflow: hidden;
    outline: unset;
    border: none;
    z-index: 1;
    font-family: Gilroy-SemiBold, sans-serif;
    cursor: pointer !important;
    text-align: center;
    text-wrap: nowrap;
    color: white;
    background: linear-gradient(to right, #5CC3FD, #263574);
}
.blueButton:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: linear-gradient(to right, #5CC3FD, #263574);
    transition: all 1s ease;
    z-index: -1;
}
.blueButton:after {
    content: '';
    position: absolute;
    z-index: -2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #5CC3FD, #364CA6);
}
.blueButton:hover {
}
.blueButton:hover:before {
    width: 100%;
}
/* Костыль для корректного отображения неразрывных дефисов в заголовках */
.nbdash {
    font-size: 1.5em;
    font-weight: 900;
}
.no-scroll {
    overflow: hidden;
}
@font-face {
    /*БОЛЬШОЙ НАКЛОННЫЙ*/
    font-family: 'Tactic Sans Extended';
    src: url('../public/fonts/Tactic-Sans-Extended/TacticSansExd-BlkIt.ttf');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    /*ТОНКИЙ НАКЛОННЫЙ*/
    font-family: 'Tactic Sans Extended Light Italic';
    src: url('../public/fonts/Tactic-Sans-Extended/TacticSansExd-LgtIt.ttf');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Gilroy-Regular';
    src: url('../public/fonts/Gilroy/Gilroy-Regular.ttf');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy-SemiBold';
    src: url('../public/fonts/Gilroy/Gilroy-SemiBold.ttf');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy-Black';
    src: url("../public/fonts/Gilroy/gilroy-black.ttf");
}
@font-face {
    font-family: 'Gilroy-Bold';
    src: url("../public/fonts/Gilroy/gilroy-bold.ttf");
}
@font-face {
    font-family: 'Gilroy-Medium';
    src: url("../public/fonts/Gilroy/Gilroy-Medium.ttf");
}
body{
    background-color: black;
}
.App{
    color: white;
}
.main-container {
    max-width: 1140px;
    padding: 0 30px;
    margin: 0 auto;
}

@media (max-width: 425px) {
    .block-title {
        text-align: center;
    }
}
/* Каждый пиксель пустим в дело*/
@media (max-width: 375px) {
    .main-container {
        padding: 0 20px;
    }
}

@media (max-width: 374px) {
    .main-container {
        padding: 0 10px;
    }
}